<template>
  <v-container
    id="ai-heating-view"
    fluid
  >
    <v-row>
      <v-col>
        <v-card rounded="xl" elevation="0">
          <v-card-title style="color: gray;">
            <span>AI-Heating</span>
            <v-spacer />
            <v-btn
              color="red"
              class="mr-5"
              @click="syncTemps"
            >
              <span class="white--text">Sync Temps</span>
            </v-btn>
            <div class="pr-5 text-caption">
              <span class="">{{ globalSettings.webport.length ? `WEBPORT: OK Valid until: ${globalSettings.webport[0].validUntil}` : 'WEBPORT: NO TOKEN' }}</span><br>
              <span>Force refresh count: {{ globalSettings.webport.length ? globalSettings.webport[0].forceRefreshed : 'Not available' }}</span>
            </div>
            <v-btn
              :disabled="!!globalSettings.webport.length"
              color="orange"
              @click="requestWebportToken"
            >
              <span class="text-caption white--text pr-2">Request Webport Token</span>
              <v-icon color="white">
                mdi-cog
              </v-icon>
            </v-btn>
            <v-btn
              class="ml-5"
              color="green"
              @click="openAddBuildingModal"
            >
              <span class="text-caption white--text pr-2">Create new building</span>
              <v-icon
                color="white"
              >
                mdi-plus-box
              </v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
      <!-- <v-col
        cols="12"
        md="6"
      >
        <v-card rounded="xl" elevation="0">

        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <v-card
          rounded="xl"
          elevation="0"
          :loading="loading"
        >
          <template slot="progress">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            />
          </template>
          <v-card-title style="color: gray;">
            Portfolio
            <v-spacer />
            <div class="pr-4">
              <v-icon
                v-if="autoFetchingEnabled"
                color="success"
                class="blink pr-3"
              >
                mdi-update
              </v-icon>
              <v-btn
                disabled
                :color="autoFetchingEnabled ? 'success' : ''"
                @click="toggleFetch"
              >
                Auto Update |{{ autoFetchingEnabled ? 'ON' : 'OFF' }}|
              </v-btn>
            </div>
            <v-btn
              icon
              :disabled="autoFetchingEnabled"
              @click="fetchDataForHCU"
            >
              <v-icon>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="building in lodash.orderBy(buildings, ['id'], ['desc'])"
                :key="building.id"
                cols="12"
                class="pb-3"
              >
                <v-card elevation="0" style="border: 1px solid rgba(0, 0, 0, 0.1); background-color: #eff4fa;">
                  <v-card-title style="font-size: 30px;" class="font-weight-thin">
                    <span class="pr-2 green--text">{{ building.buildingId }}</span> | <span class="font-weight-light pl-2">{{ building.buildingName }}</span> <span class="ml-3 mt-1" style="font-size: 20px;">{{ building.street }}, {{ building.city }}, {{ building.country }}</span><v-spacer /> <span class="text--danger" style="font-size: 24px;">{{ building.landlordName }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="2">
                        <div><span class="text-decoration-underline">AI Enabled:</span> <span :class="building.controlEnabled === true ? 'success--text' : 'red--text'">{{ building.controlEnabled }}</span></div>
                        <div><span class="text-decoration-underline">Algorithm:</span> {{ building.algorithm }}</div>
                        <div>Apartments: {{ building.propertyIds.length }}</div>
                        <!-- <div>Notes: {{ building.notes }}</div> -->
                        <div class="font-weight-bold">
                          Set point: <span class="success--text">{{ building.setPoint }}°C</span>
                        </div>
                        <div class="font-weight-bold">
                          Current:
                          <span class="success--text">{{ getLatestTemperature(building.buildingId)[1] }}°C</span>
                          <span
                            class="pl-1"
                            :class="getBuildingsTrend(building.buildingId)[1]"
                          >
                            {{ getBuildingsTrend(building.buildingId)[0] }}
                          </span>
                          <span class=" blue--text text-caption"><v-icon small color="blue">mdi-chevron-down</v-icon>{{ getBuildingsLowestOrHighest(building.buildingId).lowestTemperature }}°C</span>
                          <span class="pl-1 red--text text-caption">{{ getBuildingsLowestOrHighest(building.buildingId).highestTemperature }}°C<v-icon small color="red">mdi-chevron-up</v-icon></span>
                        </div>
                        <div class="font-weight-bold">
                          Weighted:
                          <span class="success--text">{{ getLatestTemperature(building.buildingId)[2] }}°C</span>
                        </div>
                        <div class="font-weight-bold">
                          <div>Rad current:  {{ building.actualRadiatorTempFromWebport }}°C</div>
                          <div>Rad set: {{ building.newRadiatorTempToSet }} </div>
                          <div class="red--text">
                            Circulation Pump: {{ building.pumpStatus }}
                          </div>
                        </div>
                        <v-btn
                          color="success"
                          dark
                          style="position: absolute; bottom: 15px; left: 15px;"
                          @click="openDialog(building.buildingId)"
                        >
                          Open Detail
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="7">
                        <line-chart
                          :download="{background: '#fff'}"
                          label="value"
                          suffix="°C"
                          :round="3"
                          :curve="true"
                          :discrete="false"
                          height="300px"
                          :library="libraryChart"
                          :dataset="{spanGaps: true, stepped: true, pointRadius: 1.5,}"
                          width="100%"
                          :data="findDataForCharts(building.buildingId)"
                        />
                      </v-col>
                      <v-col cols="12" md="3">
                        <l-map
                          v-if="!dialog && !loadingDialog"
                          style="height: 300px; width: 100%"
                          :zoom="16"
                          :center="[building.latitude, building.longitude]"
                        >
                          <l-tile-layer :url="url" :attribution="attribution" />
                          <l-marker :icon="icon" :lat-lng="[building.latitude, building.longitude]" />
                        </l-map>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="!buildings.length && !loading" class="text-center">
                <v-icon style="font-size: 80px;">
                  mdi-alert-circle-outline
                </v-icon><br>
                No Buildings added yet
              </v-col>
              <v-col v-if="!buildings.length && loading" class="text-center">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="100%"
                  height="300"
                  type="card"
                />
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="100%"
                  height="300"
                  type="card"
                />
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="100%"
                  height="300"
                  type="card"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="70%"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ openedDetailOfBuilding.buildingName }}
        </v-card-title>

        <v-card-text>
          <v-row style="margin-top: 30px;">
            <v-col class="">
              Buildings target temperature:
            </v-col>
            <v-col>
              <v-slider
                v-model="openedDetailOfBuilding.setPoint"
                min="18"
                max="25"
                thumb-color="green"
                color="blue"
                track-color="red"
                thumb-label="always"
              />
            </v-col>
          </v-row>
          <!-- {{ buildingHeatingDetails }} -->
          <v-row>
            <v-col>
              <apexchart
                :height="300"
                type="line"
                :options="chartOptions"
                :series="chartSeries"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <apexchart
                :height="300"
                type="line"
                :options="chart2Options"
                :series="chart2Series"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <apexchart
                :height="300"
                type="line"
                :options="chart3Options"
                :series="chart3Series"
              />
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <div v-for="(item, i) in lodash.orderBy(lastReading.readings, 'propertyId')" :key="i">
                {{ item.propertyName }} - <span :class="item.temperature !== 'unknown' ? 'green--text' : ''">{{ item.temperature }}</span> <span v-if="item.temperature === 'unknown' && item.disconnectedAt !== null">{{ dayjs(item.disconnectedAt).format('YY-MM-DD HH:mm') }}</span> <span v-if="item.notes">[<span>{{ item.notes }}</span>]</span>
              </div>
            </v-col>
          </v-row> -->
          <v-row v-for="(apartments, floor) in floorsWithApartments" :key="floor">
            <v-col cols="12">
              Floor {{ floor.charAt(1) }}
            </v-col>
            <v-col
              v-for="apartment in lodash.orderBy(apartments, ['apartment.propertyId'], ['asc'])"
              :key="apartment.aptNumber"
              cols="3"
              style="border: 1px solid gray"
              :style="apartment.apartment.temperature === 'unknown' ? 'background: red; color: white' : ''"
            >
              {{ apartment.apartment.propertyName }}
              - <span :class="apartment.apartment.temperature !== 'unknown' ? 'green--text' : ''">{{ apartment.apartment.temperature }}</span> <span v-if="apartment.apartment.temperature === 'unknown' && apartment.apartment.disconnectedAt !== null">{{ dayjs(apartment.apartment.disconnectedAt).format('YY-MM-DD HH:mm') }}</span> <span v-if="apartment.apartment.notes">[<span>{{ apartment.apartment.notes }}</span>]</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="warning"
            text
            @click="closeDetailOfABuilding"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialogAddNewBuilding" width="50%">
      <v-card>
        <v-card-title>Add New Building</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newBuilding.landlordId"
            label="Landlord Id"
            type="number"
          />
          <v-text-field
            v-model="newBuilding.landlordName"
            label="Landlord Name"
            type="text"
          />
          <v-text-field
            v-model="newBuilding.buildingName"
            label="Building Name"
            type="text"
          />
          <v-text-field
            v-model="newBuilding.buildingId"
            label="Building Id"
            type="number"
          />
          <v-text-field
            v-model="newBuilding.street"
            label="Street"
            type="text"
          />
          <v-text-field
            v-model="newBuilding.street2"
            label="Street 2"
            type="text"
          />
          <v-text-field
            v-model="newBuilding.city"
            label="City"
            type="text"
          />
          <v-text-field
            v-model="newBuilding.postCode"
            label="Post Code"
            type="text"
          />
          <v-text-field
            v-model="newBuilding.country"
            label="Country"
            type="text"
          />
          <v-divider color="red" />
          <v-text-field
            v-model="newBuilding.notes"
            label="Notes"
            type="text"
          />
          <v-text-field
            v-model="newBuilding.setPoint"
            label="Setpoint in C"
            type="number"
          />
          <v-text-field
            v-model="newBuilding.propertyIds"
            label="Property Ids (e.g. Number, Number, Number)"
            type="text"
          />
          <v-switch
            v-model="newBuilding.enabled"
            label="Enabled?"
          />
          <v-switch
            v-model="newBuilding.controlEnabled"
            label="Control Enabled?"
          />
          <v-divider color="red" />
          <div class="mt-5 text-h5">
            Webport Tags <v-spacer /> <v-btn @click="newBuilding.webportTags.push(webportTagTemplate)">
              Add
            </v-btn>
          </div>
          <div
            v-for="(object, i) in newBuilding.webportTags"
            :key="i"
            class="pa-2 mt-5"
          >
            Tag: {{ i }}
            <v-btn
              text
              color="red"
              @click="newBuilding.webportTags.splice(i, 1)"
            >
              Remove
            </v-btn>
            <v-row>
              <v-col style="border: 1px solid black;" class="ma-3">
                <v-text-field
                  v-model="object.tag"
                  label="Webport Tag"
                  type="text"
                />
                <v-select
                  v-model="object.name"
                  :items="tagNames"
                  label="Name"
                />
                <v-select
                  v-model="object.type"
                  :items="tagTypes"
                  label="Name"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="createNewBuilding">
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadingDialog" width="150">
      <v-card>
        <v-card-text style="text-align: center;">
          <v-progress-circular
            class="mt-3"
            :rotate="360"
            :size="100"
            :width="15"
            color="orange"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import AutomationLoad from '../components/AutomationLoad.vue'
// import L from 'leaflet';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { icon } from 'leaflet'
import lodash from 'lodash'
import VueApexCharts from 'vue-apexcharts'
import dayjs from 'dayjs'

export default {
  name: 'Home',

  components: {
    LMap,
    LTileLayer,
    LMarker,
    apexchart: VueApexCharts
  },
  data () {
    return {
      lodash,
      dayjs,
      autoFetchingEnabled: false,
      autoFetchInterval: null,
      selectedView: '1',
      loading: false,
      loadingDialog: false,
      dialog: false,
      openDialogAddNewBuilding: false,
      buildings: [],
      globalSettings: { webport: [] },
      indoorTempSetPoint: 22,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '<a target="_blank" href="https://homevision.se">Homevision.se</a>',
      icon: icon({
        iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.6.0/images/marker-icon.png',
        iconSize: [25, 40],
        iconAnchor: [16, 37]
      }),
      forecast: [],
      indoorTemperatureDetailedData: [],
      indoorTemperatureDataPoints: [],
      webportTagsData: [],
      buildingsTrend: null,
      openedDetailOfBuilding: {},
      libraryChart: {
        scales: {
          yAxes: [{
            ticks: {
              stepSize: 5,
              maxTicksLimit: null,
              autoSkip: true
            }
          }],
          xAxes: [{
            ticks: {
              maxTicksLimit: null,
              autoSkip: true
            }
          }]
        },
        layout: {
          padding: {
            left: 0,
            right: 4,
            top: 0,
            bottom: 0
          }
        }
      },
      newBuilding: {
        landlordId: null,
        landlordName: null,
        buildingName: null,
        buildingId: null,
        street: null,
        street2: null,
        city: null,
        postCode: null,
        country: 'Sweden',
        notes: null,
        enabled: false,
        controlEnabled: false,
        algorithm: 'JellyBeanHCUv1',
        propertyIds: null,
        setPoint: null,
        webportTags: [
          { buildingId: null, name: null, type: null, tag: null, buildingName: null }
        ]
      },
      tagNames: ['Heater Pipe in to radiators', 'Heater Pipe from radiators', 'Outdoor Temperature'],
      tagTypes: ['HeatUp', 'HeatDown', 'OutdoorTemperature'],
      webportTagTemplate: { buildingId: null, name: null, type: null, tag: null, buildingName: null },
      chartOptions: {
        chart: {
          type: 'line',
          height: '350'
        },
        colors: ['#E67E22', '#E74C3C', '#2ECC71'],
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Time'
          }
        },
        yaxis: {
          title: {
            text: 'Temperature'
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      },
      chart2Options: {
        chart: {
          type: 'line',
          height: '350'
        },
        colors: ['#2ECC71', '#3498DB', '#E74C3C'],
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Time'
          }
        },
        yaxis: {
          title: {
            text: 'Temperature'
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      },
      chart3Options: {
        chart: {
          type: 'line',
          height: '350'
        },
        colors: ['#3498DB'],
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Time'
          }
        },
        yaxis: {
          title: {
            text: 'Temperature'
          }
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          }
        }
      },
      chartSeries: [],
      chart2Series: [],
      chart3Series: [],
      lastReading: { readings: [] }
    }
  },
  computed: {
    floorsWithApartments () {
      const floorMap = {}
      this.lastReading.readings.forEach(apartment => {
        const [, floor, aptNumber] = apartment.propertyName.match(/(\d{2})(\d{2})/)
        if (!floorMap[floor]) {
          floorMap[floor] = []
        }
        floorMap[floor].push({ floor, aptNumber, apartment })
      })
      return floorMap
    }
  },
  watch: {
  },
  beforeDestroy () {
    this.stopFetching()
  },
  created () {
    this.fetchDataForHCU()
  },
  methods: {
    requestWebportToken () {
      this.$store.dispatch('requestWebportTokenForHCU').then(() => {
        this.$store.dispatch('fetchGlobalSettings').then((resp2) => {
          this.globalSettings = resp2.data
        })
      })
    },
    resetPrevErrorAndIntegral (buildingId) {
      console.log(buildingId)
      this.$store.dispatch('resetPrevErrorAndIntegral', { buildingId }).then(() => {
        this.closeDetailOfABuilding()
        this.fetchDataForHCU()
      })
    },
    fetchDataForHCU () {
      this.loading = true
      this.$store.dispatch('getDataForHCU').then((resp) => {
        console.log(resp)
        this.buildings = resp.data.buildings
        this.forecast = resp.data.forecast
        this.indoorTemperatureDetailedData = resp.data.indoorTemperatureDetailedData
        this.indoorTemperatureDataPoints = resp.data.indoorTemperatureDataPoints
        this.webportTagsData = resp.data.webportTagsData
        this.buildingsTrend = resp.data.indoorTemperatureTrends
        this.loading = false
      }).finally(
        this.$store.dispatch('fetchGlobalSettings').then((resp2) => {
          this.globalSettings = resp2.data
        })
      )
    },
    findDataForCharts (buildingId) {
      const response = [
        { name: 'Heat Down', data: [] },
        { name: 'Heat Up', data: [] },
        { name: 'Weather Forecast', data: [] },
        { name: 'Indoor Temperature', data: [] },
        { name: 'Outdoor Temperature', data: [] }
        // { name: 'Weighted indoor', data: [] }
      ]
      const indexOfBuildingInForecast = this.forecast.findIndex(item => item[0] === parseInt(buildingId))
      const indexOfBuildingInIndoorTemperatureDataPoints = this.indoorTemperatureDataPoints.findIndex(item => item[0] === parseInt(buildingId))
      const indexOfBuildingInWebportTagsData = this.webportTagsData.findIndex(item => item[0] === parseInt(buildingId))

      const interval = 10

      // Function to check if the time is within the desired interval
      function isWithinInterval (dateString, interval) {
        const date = new Date(dateString)
        return date.getMinutes() % interval === 0
      }

      if (indexOfBuildingInForecast !== -1) {
        response[2].data = this.forecast[indexOfBuildingInForecast][1]
      }

      if (indexOfBuildingInIndoorTemperatureDataPoints !== -1) {
        response[3].data = this.indoorTemperatureDataPoints[indexOfBuildingInIndoorTemperatureDataPoints][1].filter(([dateString]) => isWithinInterval(dateString, interval))
      }

      // if (indexOfBuildingInIndoorTemperatureDataPoints !== -1) {
      //   response[5].data = this.indoorTemperatureDataPoints[indexOfBuildingInIndoorTemperatureDataPoints][1].map(item => [item[0], item[2]])
      // }

      if (indexOfBuildingInWebportTagsData !== -1) {
        const filteredHeatDown = this.webportTagsData[indexOfBuildingInWebportTagsData][1].filter((item) => {
          if (item[1] === 'HeatDown') {
            return item
          }
        })

        response[0].data = filteredHeatDown.map(item => {
          return [item[2], item[3]]
        }).filter(([dateString]) => isWithinInterval(dateString, interval))

        const filteredHeatUp = this.webportTagsData[indexOfBuildingInWebportTagsData][1].filter(item => {
          if (item[1] === 'HeatUp') {
            return item
          }
        })

        response[1].data = filteredHeatUp.map(item => {
          return [item[2], item[3]]
        }).filter(([dateString]) => isWithinInterval(dateString, interval))

        const filteredOutdoor = this.webportTagsData[indexOfBuildingInWebportTagsData][1].filter(item => {
          if (item[1] === 'OutdoorTemperature') {
            return item
          }
        })

        response[4].data = filteredOutdoor.map(item => {
          return [item[2], item[3]]
        }).filter(([dateString]) => isWithinInterval(dateString, interval))
      }
      // console.log(response)
      return response
    },
    openDialog (id) {
      this.loadingDialog = true
      const building = this.buildings.find(item => item.buildingId === id)
      const dataPoints = this.indoorTemperatureDetailedData.filter(item => item.buildingId === id)
      this.openedDetailOfBuilding.buildingName = building.buildingName
      this.openedDetailOfBuilding.buildingId = building.buildingId
      this.openedDetailOfBuilding.backupIntegral = building.backupIntegral
      this.openedDetailOfBuilding.backupPrevError = building.backupPrevError
      this.openedDetailOfBuilding.dataPoints = dataPoints
      this.openedDetailOfBuilding.setPoint = building.setPoint

      this.$store.dispatch('getBuildingHeatingDetails', { buildingId: id }).then((resp) => {
        const data = resp.data
        console.log(data)
        this.chartSeries = [
          {
            name: 'Outdoor Temperature',
            data: data.outdoorTemperatureData.map(item => {
              return {
                x: dayjs(item.x).format('YYYY-MM-DDTHH:mm:ss.000Z'),
                y: item.y
              }
            })
          },
          {
            name: 'Heat Up',
            data: data.heatUpData.map(item => {
              return {
                x: dayjs(item.x).format('YYYY-MM-DDTHH:mm:ss.000Z'),
                y: item.y
              }
            })
          },
          {
            name: 'Indoor Temperature',
            data: data.avgTemperatureData.map(item => {
              return {
                x: dayjs(item.x).format('YYYY-MM-DDTHH:mm:ss.000Z'),
                y: item.y
              }
            })
          }
        ]
        this.chart2Series = [
          {
            name: 'weightedAvgTemperatureData',
            data: data.weightedAvgTemperatureData.map(item => {
              return {
                x: dayjs(item.x).format('YYYY-MM-DDTHH:mm:ss.000Z'),
                y: item.y
              }
            })
          },
          {
            name: 'lowestTemperatureData',
            data: data.lowestTemperatureData.map(item => {
              return {
                x: dayjs(item.x).format('YYYY-MM-DDTHH:mm:ss.000Z'),
                y: item.y
              }
            })
          },
          {
            name: 'highestTemperatureData',
            data: data.highestTemperatureData.map(item => {
              return {
                x: dayjs(item.x).format('YYYY-MM-DDTHH:mm:ss.000Z'),
                y: item.y
              }
            })
          }
        ]

        this.chart3Series = [
          {
            name: 'avgHumidityData',
            data: data.avgHumidityData.map(item => {
              return {
                x: dayjs(item.x).format('YYYY-MM-DDTHH:mm:ss.000Z'),
                y: item.y
              }
            })
          }
        ]
        this.lastReading = data.lastReading
        this.loadingDialog = false
        this.dialog = true
      })
    },
    closeDetailOfABuilding () {
      this.dialog = false
      this.openedDetailOfBuilding = {}
    },
    getLatestTemperature (buildingId) {
      const indexOfBuildingInIndoorTemperatureDataPoints = this.indoorTemperatureDataPoints.findIndex(item => item[0] === parseInt(buildingId))
      const length = this.indoorTemperatureDataPoints[indexOfBuildingInIndoorTemperatureDataPoints][1].length
      // console.log(this.indoorTemperatureDataPoints[indexOfBuildingInIndoorTemperatureDataPoints][1][length - 1])
      return this.indoorTemperatureDataPoints[indexOfBuildingInIndoorTemperatureDataPoints][1][length - 1]
    },
    getBuildingsTrend (buildingId) {
      const trend = this.buildingsTrend[buildingId]
      if (trend === 0) {
        return ['Stable', 'green--text']
      } else if (trend === -1) {
        return ['Decreasing', 'blue--text']
      } else if (trend === 1) {
        return ['Increasing', 'red--text']
      }
    },
    getBuildingsLowestOrHighest (buildingId) {
      const dataPoints = this.indoorTemperatureDetailedData.filter(item => item.buildingId === buildingId)
      const length = dataPoints.length
      return dataPoints[length - 1]
    },
    toggleFetch () {
      this.autoFetchingEnabled = !this.autoFetchingEnabled
      if (this.autoFetchingEnabled) {
        this.startFetching()
      } else {
        this.stopFetching()
      }
    },
    startFetching () {
      if (!this.autoFetchInterval) {
        this.fetchDataForHCU()
        this.autoFetchInterval = setInterval(this.fetchDataForHCU, 60000)
      }
    },
    stopFetching () {
      if (this.autoFetchInterval) {
        clearInterval(this.autoFetchInterval)
        this.autoFetchInterval = null
      }
    },
    openAddBuildingModal () {
      this.openDialogAddNewBuilding = !this.openDialogAddNewBuilding
    },
    createNewBuilding () {
      this.newBuilding.landlordId = parseInt(this.newBuilding.landlordId)
      this.newBuilding.buildingId = parseInt(this.newBuilding.buildingId)
      this.newBuilding.propertyIds = this.newBuilding.propertyIds.split(',').map(Number)
      this.$store.dispatch('createNewBuildingInHCU', { newBuilding: this.newBuilding }).then(() => {
        this.fetchDataForHCU()
      }).catch(err => {
        console.log(err)
      })
    },
    syncTemps () {
      this.$store.dispatch('syncTemps').then((resp) => {
        console.log(resp)
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.blink {
    -webkit-animation: blink 3s infinite both;
            animation: blink 3s infinite both;
}

@-webkit-keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
</style>
